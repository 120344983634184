import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Row, Col } from '../../ui/wrapper'
import Form, { FormGroup, Input, Textarea, Error } from '../../ui/form'
import Button from '../../ui/button'

const AddContactusRequest = () => {
const { register, handleSubmit, errors } = useForm({
    mode: "onBlur"
})

const [serverState, setServerState] = useState({
    submitting: false,
    status: null
});
const handleServerResponse = (ok, msg, form) => {
    setServerState({
        submitting: false,
        status: { ok, msg }
    });
    if (ok) {
        form.reset();
    }
};

const onSubmit = (data, e) => {
    const form = e.target;
    setServerState({ submitting: true });
    axios({
        method: "post",
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        url: "https://gdewebapi.herokuapp.com/contact",
        data: data
    })
        .then(r => {
            handleServerResponse(true, "Your inquiry has been sent successfully!. Thanks for contacting us", form);
        })
        .catch(r => {
            handleServerResponse(false, r.response.data.error, form);
        });
}



    return (
        
    <Form onSubmit={handleSubmit(onSubmit)} >  
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            ref={register({ required: 'Name is required' })}
                        //    onChange={handleInputChange}
                        //    value={contactus.name}
                        />
                        <Error>{errors.name && errors.name.message}</Error>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="10px">
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email *"
                            ref={register({
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                }
                            })}
                            // onChange={handleInputChange}
                            // value={contactus.email}
                        />
                        <Error>{errors.email && errors.email.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Country *"
                            ref={register({ required: 'Your country is required' })}
                        //    onChange={handleInputChange}
                        //    value={contactus.country}
                        />
                        <Error>{errors.country && errors.country.message}</Error>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="phone *"
                            ref={register({ required: 'Phone is required',})}
                        //    onChange={handleInputChange}
                        //    value={contactus.phone}
                        />
                        <Error>{errors.phone && errors.phone.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="company"
                            id="company"
                            placeholder="Company *"
                            ref={register({ required: 'Company is required' })}
                        //    onChange={handleInputChange}
                        //    value={contactus.company}
                        />
                        <Error>{errors.company && errors.company.message}</Error>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="position"
                            id="position"
                            placeholder="Position *"
                            ref={register({ required: 'Position is required',})}
                        //    onChange={handleInputChange}
                        //    value={contactus.position}
                        />
                        <Error>{errors.position && errors.position.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject *"
                            ref={register({ required: 'Subject is required' })}
                        //    onChange={handleInputChange}
                        //    value={contactus.subject}
                        />
                        <Error>{errors.subject && errors.subject.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="30px">
                        <Textarea
                            name="requestDescription"
                            id="requestDescription"
                            placeholder="Please describe what you need."
                            ref={register({
                                required: 'Message is required',
                                maxLength: {
                                    value: 500,
                                    message: "Maximum length is 50"
                                },
                                minLength: {
                                    value: 10,
                                    message: "Minimum length is 10"
                                }
                            })}
                        ></Textarea>
                        <Error>{errors.requestDescription && errors.requestDescription.message}</Error>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Button type="submit" disabled={serverState.submitting}> submit</Button>
                    {serverState.status && (
                        <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                            {serverState.status.msg}
                        </p>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default AddContactusRequest;