import React from 'react'
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import { TiSocialFacebook, TiSocialTwitter, TiSocialInstagram, TiSocialLinkedin } from "react-icons/ti";
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import { Row, Col } from '../../../components/ui/wrapper'
import Text from '../../../components/ui/text'
import Anchor from '../../../components/ui/anchor'
import Heading from '../../../components/ui/heading'
import Social, { SocialLink } from '../../../components/ui/social'
import { TwitterTimelineEmbed, TwitterFollowButton} from 'react-twitter-embed';
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    FooterWidgetList,
    FooterBottom
} from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
    const siteInfo = useStaticQuery(graphql`
        query FooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                    contact {
                        phone
                        email
                        address
                        
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
                }
            }
        }      
    `)
    const { copyright } = siteInfo.site.siteMetadata;
    const { facebook, twitter, instagram, linkedin } = siteInfo.site.siteMetadata.social;
    return (
        <FooterWrap {...props}>
            <FooterTop>
                <Container>
                    <Row >
                        <Col lg={3} md={3} sm={12}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">Our IT Services</Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="it-service/it-managed-services" color="textColor" hoverstyle="2">IT Managed Services</Anchor></li>
                                    <li><Anchor path="/it-service/it-audit-and-security" color="textColor" hoverstyle="2">IT Audit & Security</Anchor></li>
                                    <li><Anchor path="/it-service/api-financial-integration" color="textColor" hoverstyle="2">API Financial Integration</Anchor></li>
                                    <li><Anchor path="/it-service/software-development" color="textColor" hoverstyle="2">Software Development</Anchor></li>
                                    <li><Anchor path="/it-service/it-consultancy-and-advisory" color="textColor" hoverstyle="2">Consultancy & Advisory</Anchor></li>
                                    <li><Anchor path="/it-service/iso-certification" color="textColor" hoverstyle="2">ISO certification</Anchor></li>

                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={3} md={3} sm={12}>                            <FooterWidget>
                            <Heading as="h6" mt="-3px" mb="20px">Our Products</Heading>
                            <FooterWidgetList>
                                <li><Anchor path="/it-solution/mqpay" color="textColor" hoverstyle="2">MQPay</Anchor></li>
                                <li><Anchor path="/it-solution/erp" color="textColor" hoverstyle="2">ERP</Anchor></li>
                                <li><Anchor path="/it-solution/gdehealth-system" color="textColor" hoverstyle="2">GDHealth System</Anchor></li>
                                <li><Anchor path="/it-solution/gdereplica" color="textColor" hoverstyle="2">GDE Replica  </Anchor></li>
                                <li><Anchor path="/it-solution/prodiga-digital-suit" color="textColor" hoverstyle="2">Prodiga Suite</Anchor></li>
                                <li><Anchor path="/it-solution/rentpay" color="textColor" hoverstyle="2">Rentpay</Anchor></li>
                            </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={2} sm={12}>                            
                        <FooterWidget responsive={{ medium: { mb: '27px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">Quick links</Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/about-us" color="textColor" hoverstyle="2">About</Anchor></li>
                                    <li><Anchor path="/faq" color="textColor" hoverstyle="2">FAQ</Anchor></li>
                                    <li><Anchor path="/careers" color="textColor" hoverstyle="2">Careers</Anchor></li>
                                    <li><Anchor path="/blog" color="textColor" hoverstyle="2">Blog</Anchor></li>
                                    <li><Anchor path="/contact-us" color="textColor" hoverstyle="2">Contact Us</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>

                        <Col lg={4} md={4} sm={12}>
                            <FooterWidget>
                                <TwitterTimelineEmbed sourceType="profile" screenName="gdexpertsug" options={{height: 300}}/>
                                <TwitterFollowButton
                                    screenName={'gdexpertsug'}
                                />                           
                                </FooterWidget>
                        </Col>
                       
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={6} className="text-center text-md-left">
                            {copyright && <Text {...copyrightStyle}>&copy; {new Date().getFullYear()} {parse(copyright)}</Text>}
                        </Col>
                        <Col md={6} className="text-center text-md-right">
                            <Social space="8px" tooltip={true} shape="rounded" varient="outlined">
                                {twitter && (
                                    <SocialLink
                                        path={twitter}
                                        title="Twitter">
                                        <TiSocialTwitter />
                                    </SocialLink>
                                )}
                                {facebook && (
                                    <SocialLink
                                        path={facebook}
                                        title="Facebook">
                                        <TiSocialFacebook />
                                    </SocialLink>
                                )}
                                {instagram && (
                                    <SocialLink
                                        path={instagram}
                                        title="Instagram">
                                        <TiSocialInstagram />
                                    </SocialLink>
                                )}
                                {linkedin && (
                                    <SocialLink
                                        path={linkedin}
                                        title="Linkedin">
                                        <TiSocialLinkedin />
                                    </SocialLink>
                                )}
                            </Social>
                        </Col>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Footer.defaultProps = {
    bgcolor: '#F8F8F8',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px'
            }
        }
    }
};

export default Footer
